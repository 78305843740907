<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Komisi Payment</h2>
            </CCardHeader>
            <CCardBody>
                <span v-html="statusButton"></span>

                <commission-payment-grid ref="grid" :key="gridReload" :statusClick="statusClick"/>
                <commission-payment-status-form ref="CommissionPaymentStatusForm" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { globalfunc } from '../../../shared/GlobalFunction';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

import commissionPaymentGrid from '../CommissionPayment/Grid/CommissionPaymentGrid.vue';
import commissionPaymentStatusForm from '../CommissionPayment/Component/CommissionPaymentStatusForm.vue';

export default {
    name: 'CommissionPayment',
    components: {
        'commission-payment-grid': commissionPaymentGrid,
        'commission-payment-status-form': commissionPaymentStatusForm,
    },
    async mounted () {
        this.statusFilter();
    },
    data(){
        return{
            gridReload: 0,
            statusButton: '',
        }
    },
    methods: {
        statusClick(id){
            this.$refs.CommissionPaymentStatusForm.statusClick(id);
        },
        reload(){
            this.gridReload++;
        },
        async statusFilter() {
            this.statusButton = await this.$globalfunc.dynamicStatusButton();
            
            var vue = this;
            this.$nextTick(function () {
                $("#btnAll").on('click', function() {
                    vue.reload();
                });
                $("#btnNew").on('click', function() {
                    vue.$refs.grid.changeStatus('New');
                });
                $("#btnFinish").on('click', function() {
                    vue.$refs.grid.changeStatus('Finish');
                });
            })
        },
    }
}
</script>

<style scoped>
</style>