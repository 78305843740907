import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class CommissionEstimationService {
    readQuery(){
        var query = `
            query ($paging:ServerPaging, $status:String) {
                GetCommissionEstimation (Paging:$paging, Status:$status) {
                    commission_estimation {
                        created_by
                        created_at
                        approved_by
                        last_update
                        commission_estimation_id
                        commission_estimation_number
                        status
                        project_id
                        project_name
                        project_code
                        pre_project_code
                        sales_name
                        invoiced_project
                        invoiced_payment_project
                        total_commission_estimation
                        commission_estimation_left
                        notes
                    }
                    total
                }
            }`;
        return query;
    }
    
    async GetCommissionEstimationDetail(id) {
        const variables = {
            id : id
        }
        var query = gql`
            query ($id:Int) {
                GetCommissionEstimation (CommissionEstimationID:$id) {
                    commission_estimation {
                        created_by
                        created_at
                        approved_by
                        last_update
                        commission_estimation_id
                        commission_estimation_number
                        status
                        project_id
                        project_name
                        project_code
                        pre_project_code
                        sales_name
                        invoiced_project
                        invoiced_payment_project
                        total_commission_estimation
                        commission_estimation_left
                        notes
                        detail {
                            created_by
                            created_at
                            approved_by
                            last_update
                            commission_estimation_id
                            commission_detail_estimation_id
                            commission_detail_estimation_number
                            status
                            commission_contact_id
                            head_contact_name
                            contact_person_name
                            phone_number
                            position
                            bank_account_number
                            bank_account_name
                            npwp
                            max_commission
                            given_commission
                            notes
                        }
                    }
                    total
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetCommissionEstimation.commission_estimation[0];
    }

    async GetProjectDataQuery(){
        const variables = {
            projecttype : 1,
            status : ['Finish']
        }
        var query = gql`
            query ($projecttype:Int, $status:[String]) {
                GetTransactionProject (ProjectType:$projecttype, Status:$status) {
                    project {
                        project_id
                        project_code
                        project_name
                    }
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        var projectData = [];
        for (let i = 0; i < result.data.GetTransactionProject.project.length; i++) {
            var label = result.data.GetTransactionProject.project[i].project_code + ' (' + result.data.GetTransactionProject.project[i].project_name + ')'
            var str = {
                value: result.data.GetTransactionProject.project[i].project_id, 
                label: label
            }
            projectData.push(str);
        }
        return projectData
    }

    async GetDetailProject(id){
        const variables = {
            id : id
        }
        var query = gql`query ( $id:Int ) {
            GetTransactionProject( ProjectId:$id ){
                project {
                    created_at
                    last_update
                    project_id
                    project_code
                    project_name
                    sales_id
                    sales_name
                    status                    
                    pre_project_id
                    pre_project_code
                    pre_project_name
                    notes
                    project_detail
                    invoiced_projects
                    invoiced_payment_projects
                }
                total
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetTransactionProject.project[0];
    }

    GetContactDataQuery(){
        var query = `
            query {
                GetCommissionContactableDetailList {
                    commission_contact {
                        created_at
                        last_update
                        commission_contact_id
                        head_contact_name
                        contact_person_name
                        phone_number
                        position
                        bank_account_number
                        bank_account_name
                        npwp
                    }
                    total
                }
            }
        `;
        return query;
    }
    
    GetCommissionSpecDataQuery(){
        var query = `
            query ($paging:ServerPaging, $type:String, $addable:Boolean!) {
                GetCommission (Paging:$paging, CommissionType:$type, CommissionSpecAddable:$addable) {
                    commission {
                        created_at
                        last_update
                        commission_id
                        commission_number
                        commission_value
                        commission_contact_id
                        head_contact_name
                        contact_person_name
                        phone_number
                        position
                        bank_account_number
                        bank_account_name
                        npwp
                        status
                        commission_detail_estimation_id
                        max_commission
                        given_commission
                        attachment
                        notes
                    }
                    total
                }
            }
        `;
        return query;
    }
    
    async getRoleFlagById(id){
        const variables = {
            id : id
        }
        var query = gql`query($id:Int!){
            GetRole(RID:$id){
                rname
                role_flag{
                    flag_id,
                    flag_name,
                    flag_status
                }
            }
        }`;
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data.GetRole[0].role_flag;
    }
    
    selectedContactConverter(data, type){
        var newData = data.split(";");
        let array = [];
        for (let i = 0; i < newData.length; i++) {
            var splitData = newData[i].split("|");
            if (type == 'contact') {
                var str = {
                    commission_estimation_id: null,
                    commission_detail_estimation_id: null,
                    commission_detail_estimation_number: 'Auto',
                    commission_id: null,               
                    commission_contact_id: null,
    
                    type: null,
                    status: 'New',
                    max_commission: 0,
                    given_commission: 0,
                    notes: '',
    
                    head_contact_name: splitData[0] == 'null' ? null :splitData[0],
                    contact_person_name: splitData[1] == 'null' ? null :splitData[1],
                    phone_number: splitData[2] == 'null' ? null :splitData[2],
                    position: splitData[3] == 'null' ? null :splitData[3],
                    bank_account_number: splitData[4] == 'null' ? null :splitData[4],
                    bank_account_name: splitData[5] == 'null' ? null :splitData[5],
                    npwp: splitData[6] == 'null' ? null :splitData[6],
    
                    created_by: window.$cookies.get('Name'),
                    created_at: moment(new Date()).format("YYYY-MM-DD"),
                    approved_by: '',
                }
            }
            else if (type == 'commission-spec') {
                var str = {
                    commission_estimation_id: null,
                    commission_detail_estimation_id: null,
                    commission_detail_estimation_number: 'Auto',
                    commission_id: parseInt(splitData[7]),
                    commission_contact_id: null,
    
                    type: 'commission-spec',
                    status: 'Finish',
                    max_commission: parseFloat(splitData[8]),
                    given_commission: parseFloat(splitData[8]),
                    notes: '',
    
                    head_contact_name: splitData[0] == 'null' ? null :splitData[0],
                    contact_person_name: splitData[1] == 'null' ? null :splitData[1],
                    phone_number: splitData[2] == 'null' ? null :splitData[2],
                    position: splitData[3] == 'null' ? null :splitData[3],
                    bank_account_number: splitData[4] == 'null' ? null :splitData[4],
                    bank_account_name: splitData[5] == 'null' ? null :splitData[5],
                    npwp: splitData[6] == 'null' ? null :splitData[6],
    
                    created_by: window.$cookies.get('Name'),
                    created_at: moment(new Date()).format("YYYY-MM-DD"),
                    approved_by: '',
                }
            }
            array.push(str);
        }

        return array;
    }
    
    gridDataConverter(data){
        let array = [];
        for (let i = 0; i < data.length; i++) {
            var str = {
                commission_estimation_id: data[i].commission_estimation_id,
                commission_detail_estimation_id: data[i].commission_detail_estimation_id,
                commission_detail_estimation_number: data[i].commission_detail_estimation_number,
                commission_id: data[i].commission_id,               
                commission_contact_id: data[i].commission_contact_id,

                type: data[i].type,
                status: data[i].status,
                max_commission: data[i].max_commission,
                given_commission: data[i].given_commission,
                notes: data[i].notes,

                head_contact_name: data[i].head_contact_name,
                contact_person_name: data[i].contact_person_name,
                phone_number: data[i].phone_number,
                position: data[i].position,
                bank_account_number: data[i].bank_account_number,
                bank_account_name: data[i].bank_account_name,
                npwp: data[i].npwp,

                created_by: data[i].created_by,
                created_at: data[i].created_at,
                approved_by: data[i].approved_by,
            }
            array.push(str);
        }
        return array;
    }
    
    approverDataConverter(data, newStatus){
        let array = [];
        var str = {
            commission_estimation_id: data.commission_estimation_id,
            commission_detail_estimation_id: data.commission_detail_estimation_id,
            commission_id: data.commission_id,               

            status: newStatus,
            max_commission: data.max_commission,
            given_commission: data.given_commission,
            notes: data.notes,

            new_commission_contact: {
                head_contact_name: data.head_contact_name,
                contact_person_name: data.contact_person_name,
                phone_number: data.phone_number,
                position: data.position,
                bank_account_number: data.bank_account_number,
                bank_account_name: data.bank_account_name,
                npwp: data.npwp,
            },
        }
        array.push(str);

        return str;
    }

    async addQuery(variables){
        var query = gql`
            mutation($data: NewCommissionEstimation!){
                CreateCommissionEstimation(New: $data)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    async editQuery(variables){
        var query = gql`
        mutation($id: Int!, $data: NewCommissionEstimation!){
            UpdateCommissionEstimation(CommissionEstimationID: $id,  New: $data )
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async updateStatusQuery(variables){
        var query = gql`
        mutation($id:Int!, $data:NewCommissionDetailEstimation!){
            UpdateCommissionDetailEstimationStatus(CommissionDetailEstimationID:$id,  New:$data )
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}

export default new CommissionEstimationService();