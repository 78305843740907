import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class CommissionPaymentService {
    readQuery(){
        var query = `
            query ($paging:ServerPaging, $status:String) {
                GetCommissionPayment (Paging:$paging, Status:$status) {
                    commission_payment {
                        created_at
                        last_update
                        commission_payment_id
                        commission_payment_number
                        status
                        commission_id
                        commission_value
                        commission_number
                        commission_contact_id
                        head_contact_name
                        contact_person_name
                        phone_number
                        position
                        bank_account_number
                        bank_account_name
                        npwp
                        attachment
                        notes
                    } 
                    total
                }
            }
        `;
        return query;
    }
    
    async GetCommissionPaymentDetail(id) {
        const variables = {
            id : id,
        }
        var query = gql`
            query ($id:Int) {
                GetCommissionPayment (CommissionPaymentID:$id) {
                    commission_payment {
                        created_at
                        last_update
                        commission_payment_id
                        commission_payment_number
                        status
                        commission_id
                        commission_value
                        commission_number
                        commission_contact_id
                        head_contact_name
                        contact_person_name
                        phone_number
                        position
                        bank_account_number
                        bank_account_name
                        npwp
                        attachment
                        notes
                    } 
                    total
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetCommissionPayment.commission_payment[0];
    }

    async editQuery(variables){
        var query = gql`
        mutation($id:Int!, $data:NewCommissionPayment!){
            UpdateCommissionPayment(commissionPaymentID:$id, new:$data )
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}

export default new CommissionPaymentService();