<template>
    <div>
        <kendo-grid 
            ref="grid"
            :data-source="dataSource"
            :pageable="pageableConfig"
            :filterable="filterableConfig"
            :sortable="true"
            :columns="columns"
            :resizable="true"
            v-on:detailinit="detailInit"
        >
        </kendo-grid>
    </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

import commissionEstimationService from '../Script/CommissionEstimationService.js';

export default {
    name: 'CommissionEstimationGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editClick', 'statusClick'],
    data(){
        return{
            dataSource: new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            var swapfield = [
                                { field: "status", new_field: "\"commission_estimation\".\"status\"" },
                                { field: "sales_name", new_field: "\"Project__Sales\".\"contact_name\"" },
                                { field: "notes", new_field: "\"commission_estimation\".\"notes\"" },
                                { field: "created_by", new_field: "\"commission_estimation\".\"created_by\"" },
                                { field: "created_at", new_field: "\"commission_estimation\".\"created_at\"" },
                            ];
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter, swapfield),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort, swapfield)
                            }
                            const variables = {
                                paging : paging
                            }
                            return { 
                                query: commissionEstimationService.readQuery(),
                                variables : variables
                            }
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function(response) {
                        if(response.data.GetCommissionEstimation.commission_estimation == null){
                            return [];
                        }
                        else{
                            return response.data.GetCommissionEstimation.commission_estimation;
                        }
                    },
                    total: function(response) {
                        if(response.data.GetCommissionEstimation.commission_estimation == null){
                            return [];
                        }
                        else{
                            return response.data.GetCommissionEstimation.total;
                        }
                    },
                    model: {
                        fields: {
                            total_commission_estimation: {type: "number" },
                            commission_estimation_left: {type: "number" },
                            invoiced_project: {type: "number" },
                            invoiced_payment_project: {type: "number" },
                            created_at: {type: "date"},
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" },
            }),
            columns:  [
                { title: "Action", width: 200, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} },
                { field: "commission_estimation_number", title: "No. Estimasi Komisi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "project_code", title: "Kode Project", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "pre_project_code", title: "Kode Pra Project", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "sales_name", title: "Sales", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "total_commission_estimation", title: "Total Estimasi Komisi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { style: "text-align:right"}, format: "{0:N0}" },
                { field: "commission_estimation_left", title: "Sisa Estimasi Komisi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { style: "text-align:right"}, format: "{0:N0}" },
                { field: "invoiced_project", title: "Nilai Invoice", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { style: "text-align:right"}, format: "{0:N0}" },
                { field: "invoiced_payment_project", title: "Nilai Invoice Terbayar", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { style: "text-align:right"}, format: "{0:N0}" },
                { field: "created_by", title: "Dibuat Oleh", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "created_at", title: "Tanggal Dibuat", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, 
                    template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd') #"
                },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        }
    },
    mounted: function(){
        var editClick = this.$props.editClick;
        var statusClick = this.$props.statusClick;

        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;
        
        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.commission_estimation_id, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.commission_estimation_id, true);
        })

        ////Status Form
        GridElement.on("click", "#StatusButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            statusClick(dataItem.commission_estimation_id, dataItem.status);
        })
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionStatusButtonWithoutDelete("Estimasi Komisi")
        },
        detailInit: async function (e) {
            var data = await commissionEstimationService.GetCommissionEstimationDetail(e.data.commission_estimation_id);

            $('<div />').appendTo(e.detailCell).kendoGrid({
                ref: "CommissionEstimationDetailGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(data.detail);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    schema: {
                        data: function (response) {
                            if (response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if (response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                columns: [
                    { field: 'status', title: "Status", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'commission_detail_estimation_number', title: "No. Detail Komisi Est.", width: 120, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'head_contact_name', title: "Nama Afiliasi", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'contact_person_name', title: "Nama Kontak", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'phone_number', title: "No. Telpon", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'position', title: "Jabatan", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'bank_account_number', title: "No. Akun Bank", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'bank_account_name', title: "Nama Akun Bank", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'npwp', title: "NPWP", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: "max_commission", title: "Max Komisi", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }},
                    { field: "given_commission", title: "Komisi yg diberikan", width: 120, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }},
                    { field: 'notes', title: "Catatan", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'created_by', title: "Dibuat Oleh", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'created_at', title: "Tgl Dibuat", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                        template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd') #"
                    },
                    { field: 'approved_by', title: "Approve Oleh", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                ],
            });
        },
        changeStatus(status){
            this.dataSource = new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            var swapfield = [
                                { field: "status", new_field: "\"commission_estimation\".\"status\"" },
                                { field: "sales_name", new_field: "\"Project__Sales\".\"contact_name\"" },
                                { field: "notes", new_field: "\"commission_estimation\".\"notes\"" },
                                { field: "created_by", new_field: "\"commission_estimation\".\"created_by\"" },
                                { field: "created_at", new_field: "\"commission_estimation\".\"created_at\"" },
                            ];
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter, swapfield),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort, swapfield)
                            }
                            const variables = {
                                paging : paging,
                                status : status,
                            }
                            return { 
                                query: commissionEstimationService.readQuery(),
                                variables : variables
                            }
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function(response) {
                        if(response.data.GetCommissionEstimation.commission_estimation == null){
                            return [];
                        }
                        else{
                            return response.data.GetCommissionEstimation.commission_estimation;
                        }
                    },
                    total: function(response) {
                        if(response.data.GetCommissionEstimation.commission_estimation == null){
                            return [];
                        }
                        else{
                            return response.data.GetCommissionEstimation.total;
                        }
                    },
                    model: {
                        fields: {
                            total_commission_estimation: {type: "number" },
                            commission_estimation_left: {type: "number" },
                            invoiced_project: {type: "number" },
                            invoiced_payment_project: {type: "number" },
                            created_at: {type: "date"},
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" },
            });
        },
    }
}
</script>

<style scoped>
</style>