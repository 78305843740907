<template>
    <div>
        <div class="modal fade" id="CommissionPaymentStatusModal" tabindex="-2" aria-labelledby="CommissionPaymentStatusModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="CommissionPaymentStatusModalLabel" class="font-weight-bold">{{ModalTitle}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="p-3">
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Attachment</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <div class="row">
                                        <div class="col-10 p-0 pl-3">
                                            <div class="form-control">
                                                <div v-if="this.FileStatus == 'Uploaded'">
                                                    <a href="#" @click="fileClick()" class="label-file" id="uploadedFile"><label>{{File}}</label></a>
                                                </div>
                                                <div v-if="this.FileStatus == 'New'">
                                                    <label class="label-file">{{File}}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2 p-0 pl-2 pr-3">
                                            <label for="File" class="form-control" style="text-align: center;"><i class="fa fa-upload"></i></label>
                                            <input type="file" id="File" @input="onChangeFile()" style="display:none"/>
                                        </div>
                                    </div>
                                    <label id="errorFile" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Catatan</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <textarea id="Note" class="form-control" rows="2" v-model="Note"></textarea>
                                    <label id="errorNote" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                        </div>
                        
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
        <file-viewer ref="fileViewer"/>
    </div>
</template>


<script>
import {globalfunc} from '../../../../shared/GlobalFunction';
import { response } from '../../../../infrastructure/constant/response';
import { sweet_alert } from '../../../../infrastructure/constant/variable';

import FileViewer from '../../../../shared/FileViewer.vue';
import commissionPaymentServices from '../Script/CommissionPaymentService.js';

export default {
    name: 'CommissionStatusForm',
    components: {
        'file-viewer': FileViewer,
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle : '',

            Id : '',
            StatusData : [],
            Status : '',

            Note: '',
            
            File: '',
            FileStatus: '',
        }
    },
    methods: {
        onChangeFile(){
            var file = document.getElementById('File').files[0];
            
            this.FileStatus = 'New';
            this.File = file.name;
        },
        fileClick(){
            this.$refs.fileViewer.viewClick(this.File);
        },
        async statusClick(id){
            this.ModalTitle = 'Edit Status Komisi Payment: ';
            this.Id = id;

            var data = await commissionPaymentServices.GetCommissionPaymentDetail(this.Id);
            
            this.StatusData = await this.$globalfunc.globalDropdown('ddl_status_commission_payment');
            this.Status = data.status;
            
            this.Note = data.notes;
            
            //File
            this.File = data.attachment;
            this.FileStatus = 'Uploaded';
            
            window.$('#CommissionPaymentStatusModal').modal('show');
        },
        async saveClick(){
            this.$loading(true);

            var data = await commissionPaymentServices.GetCommissionPaymentDetail(this.Id);
            
            //file
            var file = null
            if(this.File == '' || this.File == null){
                file = null
            } else {
                file = document.getElementById('File').files[0];
            };

            const commissionPayment = {
                commission_payment_id : parseInt(this.Id),
                status : this.Status,
                commission_id : parseInt(data.commission_id),
                attachment : file,
                Notes : this.Note
            }
            
            const variables = {
                id: parseInt(this.Id),
                data : commissionPayment
            }
            
            commissionPaymentServices.editQuery(variables).then(res => {
                this.$loading(false);
                window.$('#CommissionPaymentStatusModal').modal('hide');
                this.$swal("Info", response.successUpdate, "success");
                this.$props.reload();
            }).catch(error => {
                this.$loading(false);
                var errorMessage = globalfunc.ErrorExtractor(error)
                this.$swal("Error", errorMessage, "error");
            })
            this.$loading(false);
        },
    }
}
</script>

<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
.label-file {
  width : 100%;
  overflow:hidden;
  display:inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
</style>