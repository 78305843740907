<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>{{FormType}} Estimasi Komisi : {{CommissionEstimationNo}}</h2>
            </CCardHeader>
            <CCardBody>
                <div class="px-3">
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">No. Estimasi Komisi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="CommissionEstimationNo" v-model="CommissionEstimationNo" class="font-weight-bold" readonly/>
                            <label id="errorCommissionEstimationNo" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Kode Project</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Project" class="pb-3" :options="ProjectData" v-model="Project" @input="onChangeProject()" :disabled="ProjectDisabled"/>
                            <label id="errorProject" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Nama Project</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="ProjectName" v-model="ProjectName" class="font-weight-bold" readonly/>
                            <label id="errorProjectName" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                    </CRow>

                    <CRow>                        
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Salesman</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="Sales" v-model="Sales" class="font-weight-bold" readonly/>
                            <label id="errorSales" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Kode Pra Project</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="PreProjectCode" v-model="PreProjectCode" class="font-weight-bold" readonly/>
                            <label id="errorPreProjectCode" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Nilai Invoice</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="InvoicedProject" v-model="InvoicedProject" class="font-weight-bold" readonly/>
                            <label id="errorInvoicedProject" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Nilai Invoice Terbayar</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="InvoicedPaymentProject" v-model="InvoicedPaymentProject" class="font-weight-bold" readonly/>
                            <label id="errorInvoicedPaymentProject" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Total Estimasi Komisi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="TotalCommissionEstimation" v-model="TotalCommissionEstimation" class="font-weight-bold" type="number" @change="onChangeTotalKomisi()" min="1"/>
                            <label id="errorTotalCommissionEstimation" class="form-error" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Sisa Komisi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="CommissionEstimationLeft" v-model="CommissionEstimationLeft" class="font-weight-bold" type="number" readonly/>
                            <label id="errorCommissionEstimationLeft" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>

                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Status</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Status" class="pb-3" :options="StatusData" v-model="Status" />
                            <label id="errorStatus" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold" >Catatan</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <textarea id="NoteCommissionEstimation" class="form-control" rows="2" v-model="NoteCommissionEstimation"></textarea>
                            <label id="errorNoteCommissionEstimation" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>
                    
                    <CRow class="p-3">
                        <div class="p-3 border rounded" style="width: 100%;">
                            <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Detail Estimasi Komisi</h4></label>
                            <div class="row float-right">
                                <button class="btn btn-primary" @click="addContactDataClick()" >
                                    <i class="fa fa-plus"></i> Add Kontak
                                </button>
                                &nbsp;
                                <button class="btn btn-primary" @click="addCommissionSpecDataClick()" >
                                    <i class="fa fa-plus"></i> Add Komisi Spek
                                </button>
                            </div>
                            <hr>

                            <datasource ref="contactDataSource" :data="this.ContactGridData" :page-size="10" :schema-model-fields="this.ContactSchemaModel"/>

                            <kendo-grid ref="gridContact"
                                        :data-source-ref="'contactDataSource'"
                                        :editable="true"
                                        :pageable="true"
                                        :resizable="true"
                                        :cellClose="cellClose"
                            >

                                <kendo-grid-column  :title="'Action'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:center'}"
                                                    :template="this.columnDetailButton"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'type'"
                                                    :title="'tipe Commission'"
                                                    :width="100"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :hidden="true"></kendo-grid-column>

                                <kendo-grid-column  :field="'status'"
                                                    :title="'Status'"
                                                    :width="100"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'max_commission'"
                                                    :title="'Max Komisi'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:right'}"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'given_commission'"
                                                    :title="'Komisi yang Diberikan'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:right'}"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'notes'"
                                                    :title="'Catatan'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'created_by'"
                                                    :title="'Dibuat Oleh'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'created_at'"
                                                    :title="'Tgl Dibuat'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'approved_by'"
                                                    :title="'DiApprove Oleh'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'commission_detail_estimation_id'"
                                                    :title="'Kode Detail Estimasi Komisi'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :hidden="true"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'commission_detail_estimation_number'"
                                                    :title="'No. Detail Estimasi Komisi'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'commission_id'"
                                                    :title="'Kode Komisi Spec'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :hidden="true"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'commission_contact_id'"
                                                    :title="'Kode Kontak Komisi'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :hidden="true"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'head_contact_name'"
                                                    :title="'Nama Afiliasi'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'contact_person_name'"
                                                    :title="'Nama Kontak'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'phone_number'"
                                                    :title="'No. Telpon'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'position'"
                                                    :title="'Jabatan'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'bank_account_number'"
                                                    :title="'No. Akun Bank'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'bank_account_nama'"
                                                    :title="'Nama Akun Bank'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'npwp'"
                                                    :title="'NPWP'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                            </kendo-grid>
                        </div>
                        
                        <contact-form ref="contactDataForm" :saveContactGrid="saveContactGrid"/>
                        <commission-spec-form ref="commissionSpecDataForm" :saveContactGrid="saveContactGrid"/>
                    </CRow>
                    
                    <br>
                    <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                    <label style="float: right;">&nbsp;&nbsp;</label>
                    <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close" @click="$router.push('/commission-estimation')"> Close </CButton>
                </div>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import moment from 'moment';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import { response } from '../../../../infrastructure/constant/response';
import { baseUrl } from '../../../../infrastructure/constant/connection';
import { role_flag_name } from '../../../../infrastructure/constant/variable.js';

import commissionEstServices from '../Script/CommissionEstimationService.js';
import contactDataForm from './ContactDataForm.vue';
import commissionSpecDataForm from './CommissionSpecDataForm.vue';

export default {
    name: 'CommissionEstimationForm',
    components: {
        'date-picker' : datePicker,
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
        'contact-form' : contactDataForm,
        'commission-spec-form' : commissionSpecDataForm,
    },
    props: ['reload', 'addClick'],
    data(){
        return{
            FormType: this.$route.params.formtype,
            View: this.$route.params.view,
            Error : 0,

            // //Detail
            Id: this.$route.params.id,
            CommissionEstimationId: '',
            CommissionEstimationNo: '',

            ProjectData: [],
            Project: '',
            ProjectName: '',
            ProjectDisabled: false,

            Sales: '',
            PreProjectCode: '',
            InvoicedProject: '',
            InvoicedPaymentProject: '',

            TotalCommissionEstimation: 0,
            CommissionEstimationLeft: 0,

            StatusData: [],
            Status: '',
            NoteCommissionEstimation: '',

            //grid
            ContactGridData: [],
            ContactSchemaModel: {
                commission_detail_estimation_id: { type: "string", editable: false },
                commission_detail_estimation_number: { type: "string", editable: false },
                commission_id: { type: "string", editable: false },
                commission_contact_id: { type: "string", editable: false },
                
                status: { type: "string", editable: false },                
                max_commission: { type: "number", editable: true, validation: { required:true, min:1 } },
                given_commission: { type: "number", editable: false },
                notes: { type: "string", editable: true },

                head_contact_name: { type: "string", editable: false },
                contact_person_name: { type: "string", editable: false },
                phone_number: { type: "string", editable: false },
                position: { type: "string", editable: false },
                bank_account_number: { type: "string", editable: false },
                bank_account_name: { type: "string", editable: false },
                npwp: { type: "string", editable: false },

                created_by: { type: "string", editable: false },
                created_at: { type: "string", editable: false },
                approved_by: { type: "string", editable: false },
            },

            RoleFlagStatus: false,
        }   
    },
    async mounted () {
        this.$loading(true);
        
        //GetRoleFlagStatus For Approve/Reject Button
        var roleId = this.$cookies.get('RoleID');
        var roleFlagData = await commissionEstServices.getRoleFlagById(roleId);
        var roleFlagStatus = roleFlagData.filter(obj => { return obj.flag_name == role_flag_name.estimasiKomisi_Approver });
        this.RoleFlagStatus = roleFlagStatus[0].flag_status;

        //Approve/Reject
        var vueComponent = this;
        var grid = this.$refs.gridContact.kendoWidget();
        var GridElement = grid.element;
        
        GridElement.on("click", "#ApproveButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            var detailData = commissionEstServices.approverDataConverter(dataItem, "Approved");

            vueComponent.updateStatusClick(dataItem.commission_detail_estimation_id, detailData);
        })

        GridElement.on("click", "#RejectButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            var detailData = commissionEstServices.approverDataConverter(dataItem, "Rejected");

            vueComponent.updateStatusClick(dataItem.commission_detail_estimation_id, detailData);
        })

        //Create Form
        if(this.FormType == 'Add'){
            this.CommissionEstimationId = null;
            this.CommissionEstimationNo = 'Auto';

            this.ProjectData = await commissionEstServices.GetProjectDataQuery();
            this.Project = '';
            
            this.TotalCommissionEstimation = 0;
            this.CommissionEstimationLeft = 0;

            this.StatusData = [
                { value:'New', label:'New' }
            ];
            this.Status = this.StatusData.find(c => c.value == 'New');
            this.NoteCommissionEstimation = '';

            this.SaveType = 'Add';

            this.ProjectDisabled = false;
        } 
        else {
            var data = await commissionEstServices.GetCommissionEstimationDetail(this.Id)
            this.CommissionEstimationId = this.Id;
            this.CommissionEstimationNo = data.commission_estimation_number;

            this.ProjectData = await commissionEstServices.GetProjectDataQuery();
            this.Project = this.ProjectData.find(c => c.value == data.project_id);
            this.onChangeProject();
            
            this.TotalCommissionEstimation = data.total_commission_estimation;
            this.CommissionEstimationLeft = data.commission_estimation_left;

            this.StatusData = await this.$globalfunc.globalDropdown('ddl_status_commission_estimation');
            this.Status = this.StatusData.find(c => c.value == data.status);

            this.NoteCommissionEstimation = data.notes;

            var detailData = globalfunc.objectToArrayConverter(data.detail, "CommissionEstimation-ContactDetailGet");
            this.ContactGridData = detailData;
            
            this.SaveType = 'Edit';

            if(this.View == 'true'){
                $(".save-button").hide();
            }
            
            this.ProjectDisabled = true;
        }
        
        this.$loading(false);
    },
    methods: {
        columnDetailButton(e){
            var button = `
                <div class="btn-group">
                    <button type="button" class="btn btn-success btn-sm rounded" id="ApproveButton" >
                        Approve </span>
                    </button>&nbsp;&nbsp;
                    <button type="button" class="btn btn-danger btn-sm rounded" id="RejectButton" >
                        Reject </span>
                    </button>
                </div>
            `;
            var disabledbutton = `
                <div class="btn-group">
                    <button type="button" class="btn btn-success btn-sm rounded" id="ApproveButton" disabled>
                        Approve </span>
                    </button>&nbsp;&nbsp;
                    <button type="button" class="btn btn-danger btn-sm rounded" id="RejectButton" disabled>
                        Reject </span>
                    </button>
                </div>
            `;
            return e.commission_detail_estimation_id != null ? (this.RoleFlagStatus ? (e.status == 'New' ? button : disabledbutton) : '') : '';
        },
        addContactDataClick(){
            if (this.Project.value === undefined) {
                this.$swal("Info", "Harap untuk memilih project yang akan dipakai untuk Estimasi Komisi", "info");
            }
            else {
                this.$refs.contactDataForm.addClick()
            }
        },
        addCommissionSpecDataClick(){
            if (this.Project.value === undefined) {
                this.$swal("Info", "Harap untuk memilih project yang akan dipakai untuk Estimasi Komisi", "info");
            }
            else {
                this.$refs.commissionSpecDataForm.addClick()
            }
        },
        async onChangeProject() {
            var projectDetailData = await commissionEstServices.GetDetailProject(this.Project.value)

            this.ProjectName = projectDetailData.project_name;
            this.Sales = projectDetailData.sales_name;
            this.PreProjectCode = projectDetailData.pre_project_code;
            this.InvoicedProject = projectDetailData.invoiced_projects == null ? 0 : projectDetailData.invoiced_projects;
            this.InvoicedPaymentProject = projectDetailData.invoiced_payment_projects == null ? 0 : projectDetailData.invoiced_payment_projects;            
        },
        onChangeTotalKomisi(){
            this.TotalCommissionEstimation = parseInt(this.TotalCommissionEstimation)
            this.CommissionEstimationLeft = this.TotalCommissionEstimation;
        },
        cellClose(e){
            var newDataItem = e.sender.dataItem($(e.container).parent());
            var contactData = this.ContactGridData;
            var oldDataItem = contactData.filter(obj => { return obj.commission_detail_estimation_id == newDataItem.commission_detail_estimation_id });

            if (newDataItem.commission_detail_estimation_id != null) {
                if (newDataItem.status == 'New') {
                    if (oldDataItem[0].max_commission != newDataItem.max_commission) {
                        this.$swal("Info", "Max Komisi tidak dapat diubah", "info");
                        newDataItem.max_commission = oldDataItem[0].max_commission;
                        newDataItem.dirtyFields = {};
                    }
                }
                else {
                    if (oldDataItem[0].max_commission != newDataItem.max_commission) {
                        this.$swal("Info", "Max Komisi tidak dapat diubah karena status sudah '"+ newDataItem.status +"'", "info");
                        newDataItem.max_commission = oldDataItem[0].max_commission;
                        newDataItem.dirtyFields = {};
                    }
                    if (oldDataItem[0].notes != newDataItem.notes) {
                        this.$swal("Info", "Catatan tidak dapat diubah karena status sudah '"+ newDataItem.status +"'", "info");
                        newDataItem.notes = oldDataItem[0].notes;
                        newDataItem.dirtyFields = {};
                    }
                }
            }
            else if (newDataItem.commission_detail_estimation_id != null && newDataItem.type == 'commission-spec'){
                if (newDataItem.max_commission != newDataItem.given_commission) {
                    this.$swal("Info", "Max Komisi pada komisi spek tidak dapat diubah", "info");
                    newDataItem.max_commission = newDataItem.given_commission;
                    newDataItem.dirtyFields = {};
                }
            }
            else {
                var totalMaxCommission = 0
                for(var i = 0; i < contactData.length; i++){
                    totalMaxCommission += contactData[i].max_commission;
                }
                if (totalMaxCommission + newDataItem.max_commission > this.TotalCommissionEstimation) {                    
                    this.$swal("Info", "'Total Max Komisi' tidak boleh lebih besar dari 'Total Estimasi Komisi'", "info");
                    newDataItem.max_commission = 0;
                    newDataItem.dirtyFields = {};
                }
            }
        },
        async refreshDetailItemData(commission_estimation_id){
            var data = await commissionEstServices.GetCommissionEstimationDetail(commission_estimation_id)
            var detailData = globalfunc.objectToArrayConverter(data.detail, "CommissionEstimation-ContactDetailGet");
            this.ContactGridData = detailData;
        },
        saveContactGrid(data) {
            var gridData = this.$refs.gridContact.kendoWidget().dataSource._data;
            var CommissionDetailContactData = commissionEstServices.gridDataConverter(gridData);
            var inputData = commissionEstServices.gridDataConverter(data);

            var totalMaxCommissionDetail = 0
            for (var i = 0; i < CommissionDetailContactData.length; i++){
                totalMaxCommissionDetail += CommissionDetailContactData[i].max_commission;
            }
            var totalMaxCommissionInput = 0
            for (var i = 0; i < inputData.length; i++){
                totalMaxCommissionInput += inputData[i].max_commission;
            }

            if (totalMaxCommissionDetail + totalMaxCommissionInput > this.TotalCommissionEstimation) {
                this.$swal("Info", "'Total Max Komisi' tidak boleh lebih besar dari 'Total Estimasi Komisi'", "info");
            }
            else {
                CommissionDetailContactData = CommissionDetailContactData.concat(inputData);
            }

            this.ContactGridData = CommissionDetailContactData;
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            if (this.Project.value === undefined) {
                this.errorShow('errorProject');
            }

            if (this.TotalCommissionEstimation < 1) {
                this.errorShow('errorTotalCommissionEstimation');
            }

            var contactData = this.$refs.gridContact.kendoWidget().dataSource._data;
            var totalMaxCommissionDetail = 0
            for (var i = 0; i < contactData.length; i++){
                totalMaxCommissionDetail += contactData[i].max_commission;
            }
            if(this.ContactGridData.length == 0){
                this.$swal("Error", "Detail Estimasi Komisi harus diisi terlebih dahulu", "error");
                this.Error++;
            }
            else if (totalMaxCommissionDetail > this.TotalCommissionEstimation) {
                this.$swal("Info", "'Total Max Komisi' tidak boleh lebih besar dari 'Total Estimasi Komisi'", "info");
                this.Error++;
            }
            else {
                for(var i=0;i<contactData.length;i++){
                    if(contactData[i].max_commission < 1){
                        this.$swal("Error", "Max Komisi pada Kontak '" + contactData[i].contact_person_name + "' dengan Afiliasi '" + contactData[i].head_contact_name + "' tidak boleh lebih kecil dari 1", "error");
                        this.Error++;
                        break;
                    }
                }
            }
        },
        async saveClick(){
            this.inputValidation();

            //Proses
            if(this.Error == 0){
                var gridContact = this.$refs.gridContact.kendoWidget().dataSource._data;
                var commissionEstimationDetailData = globalfunc.objectToArrayConverter(gridContact, "CommissionEstimation-ContactDetailSave");

                const commissionEstimation = {
                    commission_estimation_id: parseInt(this.CommissionEstimationId),
                    status: this.Status.value,
                    project_id: parseInt(this.Project.value),
                    total_commission_estimation: parseFloat(this.TotalCommissionEstimation),
                    notes: this.NoteCommissionEstimation,
                    detail: commissionEstimationDetailData
                }

                if (this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : commissionEstimation
                    }

                    commissionEstServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success").then(
                            this.$router.push({ path: '/commission-estimation' })
                        );
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                } 
                else if (this.SaveType == 'Edit'){
                    this.$loading(true);
                    
                    const variables = {
                        id: parseInt(this.CommissionEstimationId),
                        data : commissionEstimation
                    }
                    
                    commissionEstServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success")
                            .then(res => {
                                if(res.isConfirmed == true || res.isDismissed == true){
                                    window.location = baseUrl + '/commission-estimation'
                                }
                            });
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        async updateStatusClick(detailId, detailData){
            this.$loading(true);

            const variables = {
                id: parseInt(detailId),
                data : detailData
            }
            
            commissionEstServices.updateStatusQuery(variables).then(res => {
                this.$loading(false);
                this.$swal("Info", response.successUpdate, "success");
                this.refreshDetailItemData(this.CommissionEstimationId);
            }).catch(error => {
                this.$loading(false);
                var errorMessage = globalfunc.ErrorExtractor(error)
                this.$swal("Error", errorMessage, "error");
            })
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    } 
}
</script>

<style scoped>
.col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 12%;
    }
    .col-sm-content {
        width: 21%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
.label-file {
  width : 100%;
  overflow:hidden;
  display:inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>